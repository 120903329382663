/*+init {*/
*
{
	position: relative;
	margin: 0;
	padding: 0;
	font-size: 1em;
}
H1, H2, H3, H4, H5, H6, TH
{
	font-weight: inherit;
	=font-weight: expression(this.__FW?this.__FW:(new Function('t','return (t.__FW=t.parentNode.currentStyle.fontWeight)'))(this));
}
ADDRESS, CITE, DFN, VAR
{
	font-style: inherit;
	=font-style: expression(this.__FS?this.__FS:(new Function('t','return (t.__FS=t.parentNode.currentStyle.fontStyle)'))(this));
}
CAPTION, CODE, KBD, PRE, SAMP, TT, INPUT, TEXTAREA, SELECT, BUTTON
{
	font-family: inherit;
	=font-family: expression(this.__FF?this.__FF:(new Function('t','return (t.__FF=t.parentNode.currentStyle.fontFamily)'))(this));
}
TABLE
{
	border-spacing: 0;
}
TH
{
	text-align: left;
}
HTML, BODY, THEAD, TBODY, TFOOT, TR, TH, TD, BR
{
	position: static;
}
INPUT[type=hidden]
{
	display: none !important;
}
FIELDSET, A IMG
{
	border: 0;
}
BODY
{
	font-size: 100%;
	font-family: sans-serif;
	line-height: 1.5;
}
/*+}*/
.sky_bg
{
	height: 690px;
	/*+placement: anchor-top-left 0px 0px;*/
	position: absolute;
	left: 0px;
	top: 0px;
	background-image: url(./img/bg-body-repeat-sky.png);
	width: 100%;
	background-repeat: repeat-x;
	/*+box-shadow: 0px 2px 4px #E4E4E4;*/
	-moz-box-shadow: 0px 2px 4px #E4E4E4;
	-webkit-box-shadow: 0px 2px 4px #E4E4E4;
	box-shadow: 0px 2px 4px #E4E4E4;
	background-color: #FFFFFF;
}
BODY
{
	font-family: HelveticaNeue-Light, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
	font-weight: 300;
	font-size: 100%;
	-webkit-font-smoothing: antialiased;
	margin: 0;
	padding: 0;
	/*[disabled]background-image:url(img/bg-body-repeat-sky.png);*/
	/*[disabled]background-repeat:repeat-x;*/
	background-color: #74B31D;
	    overflow-x: hidden;;
}
TABLE
{
	border-collapse: collapse;
}
TABLE, TH, TD
{
	border: 1px solid #000000;
}
TD, TH
{
	padding: 5px;
}
TR.alt
{
	background-color: #FAFAFA;
}
H1
{
	font-size: 150%;
	padding-bottom: 10px;
}
H2
{
	font-size: 130%;
	font-weight: bold;
	padding-bottom: 5px;
	color: #74B31D;
}
.main_copy P
{
	padding: 0px 0px 15px;
}
.content UL, .content OL
{
	padding: 0 15px 15px 40px;
	/*this padding mirrors the right padding in the headings and paragraph rule above. Padding was placed on the bottom for space between other elements on the lists and on the left to create the indention. These may be adjusted as you wish.*/
}
.testimonial
{
	/*+box-shadow: 2px 2px 5px #ADADAD;*/
	-moz-box-shadow: 2px 2px 5px #ADADAD;
	-webkit-box-shadow: 2px 2px 5px #ADADAD;
	box-shadow: 2px 2px 5px #ADADAD;
	margin: 0px 0 30px;
	padding: 15px;
	font-size: 10pt;
	font-family: Georgia;
	background: #FFFFFF;
	/*[empty]color:;*/
	/*+border-radius: 10px;*/
	-moz-border-radius: 10px;
	-webkit-border-radius: 10px;
	-khtml-border-radius: 10px;
	border-radius: 10px;
	border: 3px solid #74B31D;
}
.testimonial.left:before
{
	content: '  ';
	border-color: #74B31D transparent;
	height: 0px;
	width: 0px;
	display: block;
	position: absolute;
	bottom: -15px;
	left: 50px;
	border-style: solid;
	border-width: 15px 15px 0;
}
.testimonial.left:after
{
	content: " ";
	position: absolute;
	left: 55px;
	bottom: -10px;
	border-width: 10px 10px 0;
	border-color: #FFFFFF transparent;
	border-style: solid;
	display: block;
}
.testimonial.right:before
{
	content: '  ';
	border-color: #74B31D transparent;
	height: 0px;
	width: 0px;
	display: block;
	position: absolute;
	bottom: -15px;
	left: 470px;
	border-style: solid;
	border-width: 15px 15px 0;
}
.testimonial.right:after
{
	content: " ";
	position: absolute;
	left: 475px;
	bottom: -10px;
	border-width: 10px 10px 0;
	border-color: #FFFFFF transparent;
	border-style: solid;
	display: block;
}
.quote_source
{
	text-align: right;
	font-style: italic;
	font-weight: bold;
	color: #74B31D;
}
A IMG
{
	/*this selector removes the default blue border displayed in some browsers around an image when it is surrounded by a link*/
	border: none;
}
A:link
{

	text-decoration: none;
	font-style: normal;
}
A:visited
{
	/*tcolor: inherit;*/
	text-decoration: none;
}
.ideas_list A:link, .ideas_list A:visited
{
	text-decoration: none;
	color: #000000;
}
.ideas_list A:hover
{
	text-decoration: underline;
	color: #7F7A7A;
}
A.backtocontents:link, A.backtocontents:visited
{
	font-size: 8pt;
	color: #7F7A7A;
	text-decoration: none;
}
A.backtocontents:hover
{
	text-decoration: underline;
}
.content A
{
	text-decoration: underline;
	color: #044BD6;
}
A:hover, A:active, A:focus
{
	text-decoration: underline;
}
.idea_all
{
	margin-top: 10px;
	border-top: 1px solid #DADADA;
	padding-top: 30px;
}
.nav-trigger
{
	position: absolute;
	clip: rect(0, 0, 0, 0);
}



@media only screen and (min-device-width: 761px)
{
	.footer_extra A
	{
	}
	DIV.logo
	{
		/*+placement: displace 0px 0px;*/
		position: absolute;
		margin-left: 0px;
		margin-top: 0px;
	}
	.footer_extra A
	{
		color: inherit;
	}
	.footer_logos IMG
	{
		/*[empty]padding-top:;*/
		/*+box-shadow: 2px 2px 5px #000000;*/
		-moz-box-shadow: 2px 2px 5px #000000;
		-webkit-box-shadow: 2px 2px 5px #000000;
		box-shadow: 2px 2px 5px #000000;
		margin: 0px 10px 0px 0px;
	}
	.right_flower
	{
		transform: scaleX(-1);
		-webkit-transform: scaleX(-1);
		-moz-transform: scaleX(-1);
		-ms-filter: "FlipH";
		filter: FlipH;
		/*+placement: shift 774px -186px;*/
		width: 55px;
		position: absolute;
		left: 810px;
		top: 640px;

	}
	.left_flower
	{
		width: 100px;
		height: 100px;
		position: absolute;
		top: 600px;
		left: -82px;
	}
	.img_broke
	{
		/*+placement: float-right 0px -151px;*/
		float: right;
		position: relative;
		left: 0px;
		top: -151px;
	}
	.kidwavingleft
	{
		/*+placement: displace -98px -96px;*/
		position: absolute;
		margin-left: -98px;
		margin-top: -96px;
	}
	.kidwavingright
	{
		/*+placement: displace 562px -58px;*/
		position: absolute;
		margin-left: 562px;
		margin-top: -58px;
	}
	.img_banner
	{
		/*[disabled]+placement:shift 0px 30px;*/
		padding-bottom: 30px;
	}
	DIV
	{
	}
	.footer_logos
	{
		width: 505px;
	}
	.footer_copy
	{
		position: absolute;
		bottom: -100px;
		text-transform: uppercase;
		font-size: 9px;
		text-align: center;
		width: 800px;
		color: #413F3E;
	}
	.footer_copy A
	{
		color: #413F3E;
	}
	.container
	{
		width: 80%;
		max-width: 800px;
		min-width: 800px;
		margin: 0 auto;
	}
	.social_media
	{
		/*+placement: anchor-top-right 0px 5px;*/
		position: absolute;
		right: 0px;
		top: 5px;
	}
	UL, OL, DL
	{
		/*Due to variations between browsers, it's best practices to zero padding and margin on lists. For consistency, you can either specify the amounts you want here, or on the list items (LI, DT, DD) they contain. Remember that what you do here will cascade to the .nav list unless you write a more specific selector.*/
		padding: 0;
		margin: 0;
	}
	.header
	{
		height: 162px;
	}
	.footer
	{
		/*+placement: displace 0px 690px;*/
		position: absolute;
		margin-left: 0px;
		margin-top: 690px;
	}
	DIV.contact
	{
		/*+placement: float-right 23px 51px;*/
		float: right;
		position: relative;
		left: 23px;
		top: 51px;
		font-size: 16.6pt;
		padding-right: 25px;
		text-align: right;
		font-weight: bold;
	}


	.container DIV.header
	{
	}
	.container .header
	{
	}
	DIV
	{
	}
	.header
	{
	}
	.main_photo_
	{
		-moz-box-shadow: 2px 2px 5px #888;
		-webkit-box-shadow: 2px 2px 5px #888;
		box-shadow: 2px 2px 5px #888;
		/*[disabled]margin-top:65px;*/
		/*+placement: displace 410px 228px;*/
		position: absolute;
		margin-left: 410px;
		margin-top: 228px;
		border: 7px solid #FFFFFF;
		border-bottom: 30px solid #FFFFFF;
		display: block;
	}
	.main_photo
	{
		-moz-box-shadow: 2px 2px 5px #888;
		-webkit-box-shadow: 2px 2px 5px #888;
		box-shadow: 2px 2px 5px #888;
		margin-top: 40px;
		border: 7px solid #FFFFFF;
		border-bottom: 30px solid #FFFFFF;
		display: block;
		height: 150px;
		width: 150px;
		/*+placement:float-right;*/
		float: right;
		margin-right: 40px;
		margin-left: 40px;
		margin-bottom: 40px;
	}
	.main_photo IMG
	{
		width: 150px;
		height: 150px;
	}
	.main_copy
	{
		width: 600px;
		/*[disabled]min-height:500px;*/
		display: block;
	}
	.content
	{
		background: #FFFFF6;
		/*+box-shadow: 0px 5px 5px #878787;*/
		-moz-box-shadow: 0px 5px 5px #878787;
		-webkit-box-shadow: 0px 5px 5px #878787;
		box-shadow: 0px 5px 5px #878787;
		border-radius: 10px;
		padding: 30px 0px 30px 100px;
		min-height: 500px;
	}
	.content
	{
	}
	.footer
	{
		padding: 0px;
		font-size: 9px;
		background: #74B31D;
		/*+box-shadow: 0px -2px 4px #B2B2B2;*/
		-moz-box-shadow: 0px -2px 4px #B2B2B2;
		-webkit-box-shadow: 0px -2px 4px #B2B2B2;
		box-shadow: 0px -2px 4px #B2B2B2;
		width: 100%;
		height: 300px;
		/*[disabled]margin:0px auto;*/
	}
	.footer IMG
	{
		padding: 10px;
		/*+placement: shift 300px -90px;*/
		position: relative;
		left: 300px;
		top: -90px;
	}
	.tagline
	{
		font-size: 14.5pt;
		padding-bottom: 0px;
	}
	.footer_content
	{
		font-size: 8pt;
		padding-top: 20px;
		height: 90px;
	}
	.footer_sitemap
	{
		/*+placement:float-right;*/
		float: right;
	}
	.footer_content P
	{
		font-weight: bold;
		color: #FFFFFF;
		font-size: 12pt;
	}
	.footer_extra
	{
		/*+placement: float-right 0px 0px;*/
		float: right;
		position: relative;
		left: 0px;
		top: 0px;
		margin-right: 30px;
		margin-left: 30px;
	}
	.footer_contact
	{
		/*+placement: float-right 0px 0px;*/
		float: right;
		position: relative;
		left: 0px;
		top: 0px;
	}
	.site_message
	{
		font-weight: bold;
		/*[disabled]height:0px;*/
		text-align: center;
		color: #FF0000;
		/*+placement: anchor-bottom-left 0px 10px;*/
		position: relative;
		left: 0px;
		bottom: 10px;
		width: 800px;
		top: -40px;
		/*[disabled]margin-bottom:22px;*/
		/*margin-left: -100px;*/
		font-size: 12pt;
	}
	.logo_img
	{
		display: block;
		margin-left: auto;
		margin-right: auto;
		height: 132px;
	}
	.navigation
	{
		display: none;
	}
	.headerbreak
	{
		display: inline;
	}
}

/****************************************************/
/*The following media is for iphones*/
@media only screen and (max-device-width: 760px)
{
	.headerbreak
	{
		display: block;
	}
	.right_flower
	{
		display: none;
	}
	.left_flower
	{
		display: none;
	}
	.footer_contact
	{
		display: none;
	}
	.footer_logos
	{
		display: none;
	}
	.footer_copy
	{
		display: none;
	}
	.img_banner
	{
		display: none;
	}
	.kidwavingright
	{
		display: none;
	}
	.kidwavingleft
	{
		display: none;
	}
	.menu_item
	{
		background-color: #74B31D;
		border: 1px solid #878787;
		padding-right: 15px;
		padding-left: 15px;
		color: #FFFFFF;
		font-weight: bold;
		text-align: center;
		height: 100px;
		line-height: 100px;
	}
	.menu_selected
	{
		font-weight: bold;
		background-color: #FFFFF6;
		color: #000000;
		border: 1px solid #878787;
	}
	.content
	{
		background: #FFFFF6;
		/*+box-shadow: 0px 5px 5px #878787;*/
		-moz-box-shadow: 0px 5px 5px #878787;
		-webkit-box-shadow: 0px 5px 5px #878787;
		box-shadow: 0px 5px 5px #878787;
		border-radius: 10px;
		padding: 20px 50px 30px;
		/*min-height: 500px;*/
	}
	.site_message
	{
		font-weight: bold;
		text-align: center;
		color: #FF0000;
		width: 100%;
		padding-top: 30px;
	}
	.contact
	{
		text-align: center;
		padding-bottom: 15px;
	}
	.logo_img
	{
		max-width: 100%;
    height: auto;
	}
	BODY
	{
		font-size: 200%;
	}
	.testimonial
	{
		font-size: 150%;
	}
	.navigation
	{
		list-style: none;
		background: #74B31D;
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 0;
	}
	.site-wrap
	{
		min-width: 100%;
		min-height: 100%;
		background-color: #FFF;
		position: relative;
		top: 0;
		bottom: 100%;
		left: 0;
		z-index: 1;
	}
	LABEL[for="nav-trigger"]
	{
		position: fixed;
		top: 70px;
		left: 30px;
		z-index: 2;
		width: 60px;
		height: 60px;
		cursor: pointer;
		background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' width='30px' height='30px' viewBox='0 0 30 30' enable-background='new 0 0 30 30' xml:space='preserve'><rect width='30' height='6'/><rect y='24' width='30' height='6'/><rect y='12' width='30' height='6'/></svg>");
		background-size: contain;
	}
	.nav-trigger:checked + LABEL
	{
		top: 670px;
	}
	.nav-trigger:checked ~ .site-wrap
	{
		top: 600px;
		box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.5);
	}
	.nav-trigger + LABEL, .site-wrap
	{
		transition: top 0.2s;
	}
	.menu
	{
		display: none;
	}
}
