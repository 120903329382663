


	.menu
	{
		/*[disabled]position:relative;*/
		top: 20px;
		padding-right: 20px;
		/*+placement: shift 40px 0px;*/
		position: absolute;
		left: 40px;
		top: 130px;
		}

	.menu_item
	{
		color: #FFFFFF;
		background-color: #74B31D;

		font-size: 9pt;
		text-align: center;
		font-weight: bold;

		width: 110px;
		height: 26px;
		padding: 3px;
		float: left;
		position: relative;
		left: 0px;
		top: 0px;
		display: block;

		/*+border-radius: 10px 10px 0 0;*/
		-moz-border-radius: 10px 10px 0 0;
		-webkit-border-radius: 10px 10px 0 0;
		-khtml-border-radius: 10px 10px 0 0;
		border-radius: 10px 10px 0 0;
		padding: 3px;

		/*+box-shadow: 0px 2px 6px #878787;*/
		-moz-box-shadow: 0px 2px 6px #878787;
		-webkit-box-shadow: 0px 2px 6px #878787;
		box-shadow: 0px 2px 6px #878787;

	}

	.menu_selected, .menu_item:hover
	{
		color: #000000;
		background-color: #FFFFF6;
		display: block;
		text-decoration: none;
	}
